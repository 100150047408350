import { Card, CardProps } from "@components/Card";
import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize"; // necessary for css prop to work
import styled from "@emotion/styled";
import React, { type JSX } from "react";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";

/**
 * Maximum width of a page section before it is centered
 * Max: 1224px + 64px
 */
export const MAX_PAGE_SECTION_WIDTH = "1288px";
export const MAX_LANDING_PAGE_SECTION_WIDTH = "1440px";
export const SECTION_WIDTH_VAR = "--sectionWidth";
/**
 * Emotion selector for the parent of a `PageSection` instance
 */
const PageSectionContainer = styled.section`
  width: 100%;
`;

export const headerCardPageSectionCss = css`
  padding-right: ${spacing.xs};
  padding-left: ${spacing.xs};
`;

const PageSectionContent = styled.div<{ fullWidth?: boolean }>`
  width: 100%;
  ${({ fullWidth = false }) =>
    fullWidth ? "" : `max-width: var(${SECTION_WIDTH_VAR});`}
  margin-left: auto;
  margin-right: auto;

  padding-right: ${spacing.xs};
  padding-left: ${spacing.xs};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding-right: ${spacing.xl};
      padding-left: ${spacing.xl};
    `,
  })}
`;

export interface PageSectionProps {
  className?: string;
  contentClassName?: string;
  contentCss?: CSSInterpolation;
  /**
   * Instead of rendering a `section`, render this element instead
   */
  withElement?: keyof JSX.IntrinsicElements;
  /**
   * ARIA role
   */
  role?: string;
  fullWidth?: boolean;
  children?: React.ReactNode | undefined;
}

/**
 * Common styles for each top-level section of a page
 *
 * - The element will take up full width of its parent (allowing full-bleed
 *   backgrounds)
 * - The content of the element will be padded slightly horizontally
 * - all elements inside of it will be limited to the maximum
 *   width of the page (as defined by `MAX_PAGE_SECTION_WIDTH`), and centered
 *
 * If you wish to add a style to the whole section like a full-bleed background,
 * use `@emotion/styled` on this element.
 *
 * If you wish to style the layout of the children of the section, it's easiest
 * to do that on a child of this element.
 */
export const PageSection = React.forwardRef<HTMLElement, PageSectionProps>(
  function PageSectionImpl(
    {
      children,
      className,
      contentClassName,
      contentCss,
      withElement,
      role,
      fullWidth = false,
    },
    ref
  ) {
    const Parent = withElement
      ? PageSectionContainer.withComponent(withElement)
      : PageSectionContainer;
    return (
      <Parent className={className} role={role} ref={ref}>
        <PageSectionContent
          css={contentCss}
          className={contentClassName}
          fullWidth={fullWidth}
        >
          {children}
        </PageSectionContent>
      </Parent>
    );
  }
);

export const CardPageSection = React.forwardRef<
  HTMLElement,
  PageSectionProps & {
    cardProps?: CardProps;
    cardCss?: CSSInterpolation;
  }
>(function PageSectionImpl(
  { children, cardCss, cardProps, contentCss, ...rest },
  ref
) {
  return (
    <PageSection
      contentCss={[
        contentCss,
        css`
          padding-left: 0;
          padding-right: 0;
        `,
      ]}
      {...rest}
    >
      <Card
        css={[
          cardCss,
          css`
            border-radius: 0;
            box-shadow: unset;
            ${cssForMediaSize({
              min: MediaSize.MEDIUM,
              css: css`
                box-shadow: inherit;
                padding: ${spacing.xxl};
                border-radius: 16px;
              `,
            })}
          `,
        ]}
        {...cardProps}
      >
        {children}
      </Card>
    </PageSection>
  );
});

/**
 * Page section purpose-built for consistent spacing for header titles in the
 * headerContent area of the default layout
 */
export const HeaderTitlePageSection = styled(PageSection)`
  padding-top: ${spacing.m}; /* reduce the padding-top since search has padding */
  padding-bottom: ${spacing.xl};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding-bottom: ${spacing.xxl};
    `,
  })}
`;

/**
 * Page section that adds default spacing in page's main default layout.
 */
export const DefaultPageSection = styled(PageSection)`
  margin: ${spacing.xl} 0;
`;
