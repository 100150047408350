import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";

import { rawSpacing } from "@every.org/common/src/display/spacing";
import { mapValues } from "@every.org/common/src/helpers/objectUtilities";

export { rawSpacing, spacing } from "@every.org/common/src/display/spacing";

/**
 * CSS variable used to control the spacing between elements in a horizontal
 * stack
 *
 * If you need to make an exception for the space after an individual element,
 * consider if your use case is actually multiple nested stacks; if it isn't,
 * then change this variable's value in that element's CSS
 *
 * This is more reliable than overriding margins on the child element directly,
 * because it works independently of CSS specificity rules.
 *
 * @example
 * <ul css={horizontalStackCss.m}>
 *   <li>I'm normal</li>
 *   <li>I'm a normie</li>
 *   <li
 *     css={css`
 *       ${horizontalStackSpacingCssVariable}: ${spacing.xl};
 *     `}
 *   >I need some space to breathe</li>
 *   <li>I'm conformist</li>
 * </ul>
 * // the rebellious element will have spacing.xl space come after it
 */
const horizontalStackSpacingCssVariable = "--horizStackSpacing";

/**
 * CSS mixin to use on a parent component to make each of its children display
 * horizontally side by side, with even spacing between them, known as a
 * horizontal "stack"
 *
 * Check out [Every Layout's description of stacks]{@link https://every-layout.dev/layouts/stack/}
 *
 * @example
 * // (include JSX pragma at top of file if you're using CSS prop)
 * const MyComponent: React.FCC = () => (
 *   <ul css={horizontalStackCss.m}>
 *     <li>Elem 1</li>
 *     <li>Elem 2</li>
 *   </ul>
 * )
 * // ^^ these list items will be side by side with spacing.m space between them
 */
export const horizontalStackCss: {
  [key in keyof typeof rawSpacing]: CSSInterpolation;
} = mapValues({
  obj: rawSpacing,
  mapper: (numPx) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    > * {
      &:not(:last-child) {
        /* NOTE: we can use margin-inline-end for rtl compat, when we get there
           + edge users all switch to chromium version */
        ${horizontalStackSpacingCssVariable}: ${numPx}px;
        margin-right: var(${horizontalStackSpacingCssVariable});
      }
    }
  `,
});

/**
 * CSS variable used to control the spacing between elements in a vertical
 * stack
 *
 * If you need to make an exception for the space after an individual element,
 * consider if your use case is actually multiple nested stacks; if it isn't,
 * then change this variable's value in that element's CSS
 *
 * This is more reliable than overriding margins on the child element directly,
 * because it works independently of CSS specificity rules.
 *
 * @example
 * <ul css={verticalStackCss.m}>
 *   <li>I'm normal</li>
 *   <li>I'm a normie</li>
 *   <li
 *     css={css`
 *       ${horizontalStackSpacingCssVariable}: ${spacing.xl};
 *     `}
 *   >I'm rebellious!</li>
 *   <li>I'm conformist</li>
 * </ul>
 * // the rebellious element will have spacing.xl space come after it
 */
const verticalStackSpacingCssVariable = "--vertStackSpacing";

/**
 * CSS mixin to use on a parent component to make each of its children display
 * vertically "stacked" on each other, with even spacing between them, known as
 * a vertical "stack"
 *
 * Check out [Every Layout's description of stacks]{@link https://every-layout.dev/layouts/stack/}
 *
 * @example
 * // (include JSX pragma at top of file if you're using CSS prop)
 * const MyComponent: React.FCC = () => (
 *   <ul css={verticalStackCss.l}>
 *     <li>Elem 1</li>
 *     <li>Elem 2</li>
 *   </ul>
 * )
 * // ^^ these list items will be stacked with spacing.l space between them
 *
 * @example
 * // you can also nest stacks
 * const MyComponent: React.FCC = () => (
 *   <ul css={verticalStackCss.l}>
 *     <li>Elem 1</li>
 *     <li>Elem 2</li>
 *     <div css={verticalStackCss.m}>
 *       <p>Hi</p>
 *       <p>Sup</p>
 *     </div>
 *     <li>Elem 3</li>
 *   </ul>
 * )
 * // ^^ stuff inside the div will be spaced apart with spacing.m, but elements
 * // in the ul are spaced by spacing.l
 */
export const verticalStackCss: {
  [key in keyof typeof rawSpacing]: CSSInterpolation;
} = mapValues({
  obj: rawSpacing,
  mapper: (numPx) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    > * {
      &:not(:last-child) {
        ${verticalStackSpacingCssVariable}: ${numPx}px;
        margin-bottom: var(${verticalStackSpacingCssVariable});
      }
    }
  `,
});
