import {
  InputContainer,
  InputContainerProps,
} from "@components/InputContainer";
import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";
import styled from "@emotion/styled";
import React, { useRef } from "react";

import { colorCssVars } from "src/theme/color";
import { INPUT_BORDER_RADIUS } from "src/theme/common";
import { spacing } from "src/theme/spacing";
import { textSizeCss, TextSize } from "src/theme/text";

export enum TextInputType {
  TEXT = "text",
  PASSWORD = "password",
  EMAIL = "email",
  NUMBER = "number",
  SEARCH = "search",
}

export enum EnterKeyHintType {
  SEARCH = "search",
  DONE = "done",
  ENTER = "enter",
  GO = "go",
  NEXT = "next",
  PREVIOUS = "previous",
  SEND = "send",
}

export const MAX_TEXT_INPUT_WIDTH = "600px";
const StyledInputContainer = styled(InputContainer)`
  max-width: ${MAX_TEXT_INPUT_WIDTH};
`;

export const InputBox = styled.span<{
  validationSuccess?: boolean;
  isDropdownActive?: boolean;
}>`
  padding: ${spacing.m};
  border-radius: ${(props) =>
    props.isDropdownActive
      ? `${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS} 0 0`
      : INPUT_BORDER_RADIUS};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: var(${colorCssVars.input.background.default});
  &:not(:focus-within):hover {
    background: var(${colorCssVars.input.background.hover});
  }
  &:focus-within {
    border: 1px solid
      ${({ validationSuccess = true }) =>
        validationSuccess
          ? `var(${colorCssVars.input.border.focus})`
          : `var(${colorCssVars.input.border.error})`};
    box-shadow: 0px 0px 0px 2px
      ${({ validationSuccess = true }) =>
        validationSuccess
          ? `var(${colorCssVars.input.outline.focus})`
          : `var(${colorCssVars.input.outline.error})`};
    background: var(${colorCssVars.input.background.focus});
  }

  border: 1px solid
    ${({ validationSuccess = true }) =>
      validationSuccess
        ? `var(${colorCssVars.input.background.default});`
        : `var(${colorCssVars.input.border.error})`};

  ${({ validationSuccess = true }) =>
    validationSuccess &&
    `box-shadow: 0px 0px 0px 2px: var(${colorCssVars.input.outline.error})`};
`;

const inputStyles = css`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 0;
  background: transparent;
  ${textSizeCss[TextSize.s]};
  color: var(${colorCssVars.text.body});
  caret-color: var(${colorCssVars.input.caret});

  ::placeholder {
    color: var(${colorCssVars.text.secondary});
  }

  :focus {
    outline: none;
  }
`;

const TextInputElem = styled.input`
  ${inputStyles}
`;

const InputPrefix = styled.span`
  color: var(${colorCssVars.text.secondary});
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const InputSuffix = styled.span`
  color: var(${colorCssVars.text.secondary});
  display: flex;
  justify-content: center;
`;

interface BaseTextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">,
    // need to update `ref` attribute to not allow legacy values, or else you get errors
    React.RefAttributes<HTMLInputElement> {
  type?: TextInputType;

  /**
   * If present, renders an element before the actual input field
   *
   * - Defaults text/icons to be colored as secondary (grayed), to distinguish
   *   from the actual input value
   * - If passed an SVG icon, also applies a right margin to separate from the
   *   input
   * - Do not confuse this with the standard `prefix` field, which is used in
   *   the RDFa spec (https://en.wikipedia.org/wiki/RDFa)
   */
  inputPrefix?: React.ReactNode;
  /**
   * Stopgap for now to prevent TextInput from overriding icon's default color
   * and height, with goal to get rid of that behavior altogether and rely on
   * Icon's explicit sizing and coloring semantics
   *
   * @default true
   *
   * @see IconProps
   */
  setInputPrefixColorAndHeight?: boolean;

  /**
   * If present, renders an element after the actual input field
   */
  inputSuffix?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  inputBoxCss?: CSSInterpolation;
  enterKeyHint?: EnterKeyHintType;
}

export type TextInputProps = BaseTextInputProps & InputContainerProps;

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  function TextInputWithRef(
    {
      type,
      name,
      labelText,
      inputPrefix,
      inputSuffix,
      className,
      onChange,
      description,
      collapseDescriptionSpace = false,
      validationStatus,
      setInputPrefixColorAndHeight = true,
      inputBoxCss,
      "data-tname": dataTName,
      ...rest
    },
    ref
  ) {
    const inputRef = useRef<HTMLInputElement>(undefined);
    return (
      <StyledInputContainer
        data-tname={`${dataTName}-container`}
        className={className}
        name={name}
        labelText={labelText}
        description={description}
        collapseDescriptionSpace={collapseDescriptionSpace}
        validationStatus={validationStatus}
      >
        <InputBox
          css={inputBoxCss}
          validationSuccess={validationStatus?.success}
          onClick={() => inputRef.current && inputRef.current.focus()}
        >
          {inputPrefix && <InputPrefix>{inputPrefix}</InputPrefix>}
          <TextInputElem
            ref={(elem) => {
              if (!elem) {
                return;
              }
              inputRef.current = elem;
              if (!ref) {
                return;
              }
              if (typeof ref === "function") {
                ref(elem);
              } else {
                (ref as React.MutableRefObject<HTMLInputElement>).current =
                  elem;
              }
            }}
            type={type ? type : "text"}
            name={name}
            data-tname={dataTName}
            onChange={onChange}
            {...rest}
          />
          {inputSuffix && <InputSuffix>{inputSuffix}</InputSuffix>}
        </InputBox>
      </StyledInputContainer>
    );
  }
);

interface BaseComboboxProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">,
    // need to update `ref` attribute to not allow legacy values, or else you get errors
    React.RefAttributes<HTMLInputElement> {
  dropdown?: React.ReactNode;
  isDropdownActive?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  enterKeyHint?: EnterKeyHintType;
}

type ComboboxProps = BaseComboboxProps &
  BaseTextInputProps &
  InputContainerProps;

export const Combobox = React.forwardRef<HTMLInputElement, ComboboxProps>(
  function ComboboxWithRef(
    {
      type,
      name,
      labelText,
      inputPrefix,
      setInputPrefixColorAndHeight = true,
      inputSuffix,
      className,
      onChange,
      description,
      collapseDescriptionSpace,
      isDropdownActive,
      dropdown,
      validationStatus,
      "data-tname": dataTName,
      ...rest
    },
    ref
  ) {
    const inputRef = useRef<HTMLInputElement>(undefined);

    return (
      <StyledInputContainer
        css={css`
          /* Negate automatic vertical spacing applied by InputContainer */
          ${DropdownContainer} {
            margin-top: -${spacing.s};
          }
        `}
        className={className}
        data-tname={`${dataTName}-container`}
        name={name}
        labelText={labelText}
        description={description}
        collapseDescriptionSpace={collapseDescriptionSpace}
        validationStatus={validationStatus}
      >
        <InputBox
          validationSuccess={validationStatus?.success}
          onClick={() => inputRef.current && inputRef.current.focus()}
          isDropdownActive={isDropdownActive}
        >
          {inputPrefix && <InputPrefix>{inputPrefix}</InputPrefix>}
          <TextInputElem
            ref={(elem) => {
              if (!elem) {
                return;
              }
              inputRef.current = elem;
              if (!ref) {
                return;
              }
              if (typeof ref === "function") {
                ref(elem);
              } else {
                (ref as React.MutableRefObject<HTMLInputElement>).current =
                  elem;
              }
            }}
            type={type ? type : "text"}
            name={name}
            data-tname={dataTName}
            onChange={onChange}
            {...rest}
          />
          {inputSuffix && <InputSuffix>{inputSuffix}</InputSuffix>}
        </InputBox>
        <DropdownContainer>{dropdown}</DropdownContainer>
      </StyledInputContainer>
    );
  }
);

type BaseTextAreaProps = Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  "ref" | "as"
> &
  // need to update `ref` attribute to not allow legacy values, or else you get errors
  React.RefAttributes<HTMLTextAreaElement> & {
    resizable?: boolean;
  } & { pressEnterToSubmit?: boolean; onSubmit?: () => void };

type TextAreaProps = BaseTextAreaProps & InputContainerProps;

const TextAreaElem = styled.textarea<{ resizable: boolean }>`
  ${inputStyles}

  /* Without this we get a weird scrolling artifact in a one-line textarea. */
  line-height: inherit;

  ${({ resizable }) => !resizable && `resize: none`}
`;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextAreaWithRef(
    {
      name,
      resizable = false,
      labelText,
      className,
      description,
      collapseDescriptionSpace,
      validationStatus,
      pressEnterToSubmit,
      onSubmit,
      "data-tname": dataTName,
      ...rest
    },
    ref
  ) {
    const inputRef = useRef<HTMLTextAreaElement>(undefined);

    return (
      <StyledInputContainer
        className={className}
        data-tname={`${dataTName}-container`}
        name={name}
        labelText={labelText}
        description={description}
        collapseDescriptionSpace={collapseDescriptionSpace}
        validationStatus={validationStatus}
      >
        <InputBox
          validationSuccess={validationStatus?.success}
          onClick={() => inputRef.current && inputRef.current.focus()}
        >
          <TextAreaElem
            ref={(elem) => {
              if (!elem) {
                return;
              }
              inputRef.current = elem;
              if (!ref) {
                return;
              }
              if (typeof ref === "function") {
                ref(elem);
              } else {
                (ref as React.MutableRefObject<HTMLTextAreaElement>).current =
                  elem;
              }
            }}
            name={name}
            data-tname={dataTName}
            resizable={resizable}
            onKeyDown={(e) => {
              if (pressEnterToSubmit && e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                onSubmit && onSubmit();
              }
            }}
            {...rest}
          />
        </InputBox>
      </StyledInputContainer>
    );
  }
);
