import { CharacterCounter } from "@components/CharacterCounter";
import { NewlineAwareText } from "@components/NewlineAwareText";
import { TextArea } from "@components/TextInput";
import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";
import styled from "@emotion/styled";
import React, { useState, useRef, useEffect } from "react";

import { EditableTextInterface } from "src/hooks/useEditableText";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { compositionTextCss, FontWeight } from "src/theme/text";
import { trackEvent } from "src/utility/analytics";
import { getWindow } from "src/utility/window";

interface EditableTextProps {
  /**
   * Interface that holds state information for the editable text.
   */
  editableTextInterface: EditableTextInterface;

  /**
   * If set, shows this text when not in edit mode.
   */
  textToDisplay?: string;

  /**
   * If true, sets the text field to not be editable.
   */
  disabled?: boolean;

  /**
   * Placeholder text before user enters anything.
   */
  placeholder?: string;

  /**
   * If set, shows character counter when user is over the character limit in
   * edit mode.
   */
  maxCharCount?: number;

  /**
   * Newlines are allowed by default. If true, disables newlines.
   */
  disableNewlines?: boolean;

  autoFocus?: boolean;
  // Sometimes onBlur resolution can block form submitting (seems like this only
  // happens sometimes) - https://github.com/facebook/react/issues/4210
  // This was an issue in the donate modal, so we make onBlur saving configurable.
  saveOnBlur?: boolean;
  /**
   * Styles to apply to comment when not editing
   */
  viewCommentCss?: CSSInterpolation;
  /**
   * Styles to apply to comment input while editing
   */
  editCommentCss?: CSSInterpolation;
  /**
   * Sets the initial text to display in the comment before editing; if present,
   * by default textbox will not be in editing mode on first render (unless
   * initiallyEditing is true)
   */
  initialText?: string | null;
  /**
   * If true, will enter editing mode when the component loads, even if initialText is present
   */
  initiallyEditing?: boolean;
  disableDescription?: boolean;
  alwaysEditing?: boolean;
  forceRows?: number;
  textEnding?: string | React.ReactNode;
  linkTarget?: string;
  "data-tname": string;
  shortDescription?: boolean;
}

const Text = styled.article`
  ${compositionTextCss};
  overflow-wrap: break-word;
  a {
    font-weight: ${FontWeight.MEDIUM};
  }
`;

const EXPAND_COMMENT_EDITOR_LENGTH = 24;

/**
 * Component that contains and allows text to be editable upon click.
 */
export const EditableText: React.FCC<EditableTextProps> = ({
  editableTextInterface,
  textToDisplay,
  disabled,
  viewCommentCss,
  editCommentCss,
  saveOnBlur,
  placeholder = "I gave because...",
  maxCharCount,
  autoFocus,
  disableNewlines,
  textEnding,
  initialText,
  initiallyEditing,
  alwaysEditing,
  disableDescription,
  forceRows,
  linkTarget,
  "data-tname": dataTName,
  shortDescription,
}) => {
  const {
    text,
    setText,
    errorSavingText,
    setErrorSavingText,
    isSaving,
    hasTextChanged,
    saveText,
  } = editableTextInterface;
  const [editing, setEditing] = useState(
    alwaysEditing || initiallyEditing || !text
  );
  const [shouldFocus, setShouldFocus] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const window = getWindow();
  const isCoarsePointer: boolean | undefined =
    window?.matchMedia("(pointer: coarse)").matches;

  useEffect(() => {
    initialText && setEditing(alwaysEditing || !initialText);
  }, [alwaysEditing, initialText]);

  useEffect(() => {
    if (editing && shouldFocus && textAreaRef.current) {
      // Focus on the element.
      textAreaRef.current.focus();
      // Select the end of the comment.
      textAreaRef.current.setSelectionRange &&
        textAreaRef.current.setSelectionRange(9999, 9999);
      setShouldFocus(false);
    }
  }, [editing, shouldFocus]);

  if (disabled || (!editing && !errorSavingText)) {
    return (
      <Text
        data-tname={dataTName}
        css={[
          !disabled
            ? css`
                cursor: pointer;
              `
            : undefined,
          viewCommentCss,
        ]}
        onClick={(e) => {
          if (!disabled) {
            setEditing(true);
            setShouldFocus(true);
          }
        }}
      >
        <NewlineAwareText
          css={[
            css`
              display: inline;
            `,
            shortDescription && truncatedTextCss({ numLines: 4 }),
          ]}
          content={textToDisplay ? textToDisplay : text}
          originalText={text}
          textEnding={textEnding}
          linkTarget={linkTarget}
        />
      </Text>
    );
  }

  const description = isSaving ? (
    "Saving..."
  ) : hasTextChanged ? (
    <CharacterCounter
      text={
        maxCharCount && text.length > maxCharCount
          ? "Text is too long"
          : isCoarsePointer
          ? "Tap anywhere to save"
          : "Press enter to save"
      }
      length={text.length}
      maxLength={maxCharCount}
    />
  ) : undefined;

  async function trySaveText() {
    if (!hasTextChanged || !saveText || (await saveText())) {
      // We always show the editor if the comment is empty
      if (text && text.length > 0) {
        setEditing(alwaysEditing || false);
      }
    }
    // if initial textarea was empty and textarea is still empty
    // fire textarea onblur to lose focus
    if (textAreaRef.current && !hasTextChanged) {
      textAreaRef.current.blur();
    }
  }

  function onBlur() {
    if (dataTName) {
      trackEvent(`${dataTName} onBlur`, {});
    }

    if (saveOnBlur) {
      trySaveText();
    }
  }

  function onFocus() {
    if (dataTName) {
      trackEvent(`${dataTName} onFocus`, {});
    }
  }

  return (
    <TextArea
      data-tname={dataTName}
      css={editCommentCss}
      placeholder={placeholder}
      value={text || ""}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updatedText = e.target.value;
        if (disableNewlines) {
          updatedText = updatedText.replace("\n", "");
        }
        setText(updatedText);
        // if error exist + user starts typing again
        if (errorSavingText) {
          setErrorSavingText(undefined);
          setEditing(true);
        }
      }}
      pressEnterToSubmit={!isCoarsePointer}
      onSubmit={trySaveText}
      description={disableDescription ? undefined : description}
      collapseDescriptionSpace
      disabled={isSaving}
      onBlur={onBlur}
      onFocus={onFocus}
      validationStatus={
        errorSavingText
          ? { success: false, message: errorSavingText }
          : undefined
      }
      // Modify textarea size if the user enters a newline or the comment size
      // gets large enough. We cap the textarea size to indicate how many lines
      // of text will show up when the comment is displayed.
      rows={
        forceRows ||
        (text &&
          (text.length > EXPAND_COMMENT_EDITOR_LENGTH || text.includes("\n")))
          ? 4
          : 1
      }
      ref={textAreaRef}
      // let the caller decide if autofocus is acceptable
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
  );
};
