import { AvatarSize, RawAvatar } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { UserAvatar } from "@components/Avatar/UserAvatar";
import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { LabeledCauseIllustration } from "@components/CauseIllustration/CauseIllustrationLabel";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { LoadingIcon } from "@components/LoadingIndicator";
import { TagIllustrationLabel } from "@components/TagIllustrationLabel";
import { useAllCauses } from "@components/Tags/TagSelector";
import {
  TextInput,
  TextInputType,
  TextInputProps,
} from "@components/TextInput";
import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";
import styled from "@emotion/styled";
import composeRefs from "@seznam/compose-react-refs";
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import Fuse from "fuse.js";
import React, {
  useContext,
  forwardRef,
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  FormEvent,
  type JSX,
} from "react";
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";

import { NonprofitResponse } from "@every.org/common/src/codecs/entities";
import { Location } from "@every.org/common/src/codecs/location";
import { Username } from "@every.org/common/src/codecs/username";
import {
  CauseCategory,
  ValidCauseCategory,
  CauseMetadata,
  FeedItemType,
  NonprofitType,
  TagNameByCauseCategory,
  SearchQueryParam,
  DonationFlowPaymentOption,
} from "@every.org/common/src/entity/types";
import { normalizeEin } from "@every.org/common/src/entity/types/ein";
import { CustomEvent } from "@every.org/common/src/helpers/analytics";
import {
  ClientRouteName,
  URLFormat,
  getRoutePath,
  getDonateRoutePath,
  DonateModalUrlParams,
  DONATE_HASH,
} from "@every.org/common/src/helpers/clientRoutes";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";
import { wrapIndex } from "@every.org/common/src/helpers/number";
import { ListParams } from "@every.org/common/src/routes/index";

import { ReactComponent as PlaceholderIllustration } from "src/assets/illustrations/nonprofit.svg";
import { Link } from "src/components/Link";
import { AuthContext } from "src/context/AuthContext";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { AuthState } from "src/context/AuthContext/types";
import { fetchPublicHomeFeed } from "src/context/DonationsContext/actions";
import { fetchNonprofit } from "src/context/NonprofitsContext/actions";
import { SearchContext, submitSearchAction } from "src/context/SearchContext";
import {
  useSearchOptionsFromUrl,
  FILTER_MODAL_URL_PARAM,
  useSearchRoute,
} from "src/context/SearchContext/helpers";
import {
  LocalSearchRouteNames,
  SearchStateValue,
} from "src/context/SearchContext/types";
import { useTrendingTags } from "src/context/TagContext/hooks";
import { TrendingTagsFetchStatus } from "src/context/TagContext/types";
import { useEdoTheme } from "src/context/ThemeContext";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars, lightBgThemeCss } from "src/theme/color";
import { BORDER_RADIUS, INPUT_BORDER_RADIUS } from "src/theme/common";
import {
  cssForMediaSize,
  MediaSize,
  useMatchesScreenSize,
} from "src/theme/mediaQueries";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import {
  TextSize,
  textSizeCss,
  secondaryMetadataTextCss,
} from "src/theme/text";
import { getTestingId, useStatSigLayer } from "src/utility/abtesting";
import {
  ClickAction,
  ClientTrackingRoute,
  setEntry,
  trackEvent,
} from "src/utility/analytics";
import { logger } from "src/utility/logger";
import {
  NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT,
  NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT_L,
} from "src/utility/pageMetadata";
import { getWindow } from "src/utility/window";

const WEBSITE_ORIGIN =
  process.env.REACT_APP_WEBSITE_ORIGIN ||
  process.env.NEXT_PUBLIC_WEBSITE_ORIGIN ||
  process.env.WEBSITE_ORIGIN ||
  "https://www.every.org";

const MAX_SEARCH_TRENDING = 5;
const MAX_SHOW_CAUSES = 6;
const MAX_CAUSES_ROWS = 3;

const VALID_CAUSE_CATEGORIES = Object.values(CauseCategory).filter(
  (c): c is ValidCauseCategory => c !== CauseCategory.UNKNOWN
);

export enum AutocompleteSection {
  NONPROFITS = "nonprofits",
  FUNDRAISERS = "fundraisers",
  USERS = "users",
  GROUPS = "groups",
  FUNDS = "funds",
}

const iconStyle = css`
  margin-right: ${spacing.xs};
`;

const PLACEHOLDER_CSS = css`
  height: 100%;
  width: auto;
`;

interface ConstructorMatch {
  value: string;
  data: {
    url: string;
    id?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cloudinary_id?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    image_url?: string;
  };
}

const PINNED_TRENDING_SLUGS: string[] = [];

export interface AutocompleteResult {
  section: AutocompleteSection;
  title: string;
  url: string;
  id: string;
  username?: string;
  slug?: string;
  ein?: string;
  type?: NonprofitType;
  /* eslint-disable @typescript-eslint/naming-convention */
  cloudinary_id?: string;
  image_url?: string;
  /* eslint-enable @typescript-eslint/naming-convention */
  hidden?: boolean;
}
const constructorKey = assertEnvPresent(
  process.env.NEXT_PUBLIC_CONSTRUCTOR_PUBLIC_API_KEY ||
    process.env.REACT_APP_CONSTRUCTOR_PUBLIC_API_KEY,
  "CONSTRUCTOR_PUBLIC_API_KEY"
);
// The return keys are section names as created in constructor.io, i.e.: nonprofits, users, fundraisers.

export type AutocompleteResultsMap = Map<string, AutocompleteResult[]>;

export async function queryConstructor(
  searchTerm: string
): Promise<AutocompleteResultsMap> {
  const ein = normalizeEin(searchTerm);
  const term = encodeURI(ein ? ein : searchTerm);
  const map: AutocompleteResultsMap = new Map();
  if (!term) {
    return map;
  }
  const uri = `https://ac.cnstrc.com/autocomplete/${term}?key=${constructorKey}`;
  const request = new Request(uri);
  const response = await fetch(request);
  if (response.status >= 200 && response.status < 300) {
    const responseData = await response.json();
    const searchResults = Object.fromEntries(
      Object.entries(responseData.sections)
        .map(([section, sectionMatches]) => [
          section,
          (sectionMatches as ConstructorMatch[]).map(
            (match: ConstructorMatch) => ({
              section,
              title: match.value,
              ...match.data,
            })
          ),
        ])
        .filter(([, matches]) => matches.length > 0)
    ) as { [key: string]: AutocompleteResult[] };

    const [nonprofits, groups, funds]: AutocompleteResult[][] = [[], [], []];

    searchResults["nonprofits"] &&
      searchResults["nonprofits"]
        .filter(({ hidden }) => !hidden)
        .forEach((item) => {
          switch (item.type) {
            case NonprofitType.COMMUNITY:
              groups.push(item);
              break;
            case NonprofitType.FUND:
              funds.push(item);
              break;
            case NonprofitType.NONPROFIT:
            default:
              // In case we get something we don't expect (or it isn't set), treat it as a nonprofit
              nonprofits.push(item);
          }
        });

    const users = searchResults["users"]?.filter(({ hidden }) => !hidden);

    const fundraisers = searchResults["fundraisers"]?.filter(
      ({ hidden }) => !hidden
    );

    /**
     * Please keep the order!
     * Or change, if you need to change the order of sections in autocomplete searh dropdown
     */
    funds && funds.length && map.set("funds", funds);
    nonprofits && nonprofits.length && map.set("nonprofits", nonprofits);
    groups && groups.length && map.set("groups", groups);
    users && map.set("users", users);
    fundraisers && map.set("fundraisers", fundraisers);
  }
  return map;
}

export async function notifyConstructorClick({
  section,
  itemId,
  input,
  actionType,
  groupId,
  userId,
  authState,
}: {
  section: string;
  itemId: string;
  input: string;
  actionType: "click" | "enter";
  userId: string | undefined;
  groupId?: string;
  authState: AuthState;
}): Promise<void> {
  const userDeviceIdHash = getTestingId({ authState });
  const sessionNumber = 0;
  const body = {
    user_input: input,
    tr: actionType,
    item_id: itemId,
    group_id: groupId,
  };
  const uri = `https://ac.cnstrc.com/v2/behavioral_action/autocomplete_select?key=${constructorKey}&c=everydotorg&i=${userDeviceIdHash}&s=${sessionNumber}&${
    userId ? `ui=${userId}` : ""
  }&section=${section}`;
  const request = new Request(uri);
  const response = await fetch(request, {
    method: "post",
    body: JSON.stringify(body),
  });
  if (response.status < 200 && response.status >= 300) {
    logger.error({
      message: "Failed to nofify constructor.io of search click",
    });
  }
}

export const getLocationPrediction = async (
  input: string,
  address?: string
): Promise<Location | null> => {
  if (!input?.trim()) {
    return null;
  }
  try {
    const window = getWindow();
    if (!(window?.google && window?.google?.maps)) {
      await import(
        /* webpackIgnore: true */
        `https://maps.googleapis.com/maps/api/js?key=${assertEnvPresent(
          process.env.REACT_APP_PLACES_API_KEY ||
            process.env.NEXT_PUBLIC_PLACES_API_KEY,
          "PLACES_API_KEY"
        )}&libraries=places`
      );
    }
    const autocompleteService = new google.maps.places.AutocompleteService();

    let suggestion: google.maps.places.AutocompletePrediction | null = null;

    const response = await autocompleteService.getPlacePredictions({
      input,
      // only get cities predictions
      // https://developers.google.com/maps/documentation/places/web-service/supported_types
      types: ["(cities)"],
    });

    suggestion = response?.predictions?.[0] || null;

    // if currentAddress
    // (an address that has already been selected by the location filter)
    // matches the search query, do not calculate the new location
    if (suggestion?.description === address) {
      return null;
    }

    if (suggestion) {
      if (
        input.toLocaleLowerCase() ===
        suggestion.structured_formatting.main_text.toLocaleLowerCase()
      ) {
        const geocode = await geocodeByPlaceId(suggestion.place_id);
        const latLng = await getLatLng(geocode[0]);

        return {
          address: suggestion.description,
          lat: latLng.lat,
          lng: latLng.lng,
        };
      }
    }

    return null;
  } catch (e) {
    logger.error({
      error: e,
      message: "Could not get location prediction for input: " + input,
    });
    return null;
  }
};

// Loading icon has no padding whereas the search icon does. Add padding manually.
const loadingIconStyle = css`
  padding: 0 4px;
  ${iconStyle};
`;

const SearchForm = styled.form`
  position: relative;
`;
const StyledTextInput = styled(TextInput)`
  max-width: inherit;
  z-index: 3;
`;

const focusedSearchCss = css`
  &:focus-within {
    border: 1px solid transparent;
    background: var(${colorCssVars.input.background.focus});
  }
  border-radius: ${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS} 0 0;
`;
export const BrowseDropdownContainer = styled.div`
  position: absolute;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-top: 1px solid var(${colorCssVars.dividerSoft});
  ${lightBgThemeCss}
  width: 100%;
  padding: ${spacing.m};
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  z-index: 2;
  overflow-y: auto;
  max-height: calc(
    100vh - ${NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT}px - ${spacing.m}
  );

  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      max-height: calc(100vh - ${NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT_L}px);
    `,
  })}

  > *:not(:last-child) {
    margin-bottom: ${spacing.m};
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -${spacing.xs};
  }

  > h3 {
    ${secondaryMetadataTextCss}
  }
`;

const suggestionGroupListCss = (rows: number) => css`
  height: calc(40px * ${rows});
  overflow: hidden;
`;
export interface SearchbarProps extends TextInputProps {
  initiallyOpen?: boolean;
  alwaysOpen?: boolean;
  autoFocused?: boolean;
  escapeOverlayCss?: CSSInterpolation;
  disableScrollLock?: boolean;
  autocompleteSection?: AutocompleteSection | AutocompleteSection[];
  showTrendingNonprofits?: boolean;
  showTrendingCauses?: boolean;
  featuredNonprofits?: NonprofitResponse[];
  donateMethod?: DonationFlowPaymentOption | DonationFlowPaymentOption[];
  focusCss?: CSSInterpolation;
}

interface SuggestionItem {
  selectedCss: CSSInterpolation;
  node: JSX.Element;
  onEnterAction: () => void;
}

interface SuggestionGroup {
  title: string;
  items: SuggestionItem[];
  rows?: number;
}

const EscapeOverlay = styled.div`
  position: fixed;
  /* transforms on parents cause position: fixed to be relative to that
   * "containing block" instead of the viewport, so overcompensate in a
   * fool-proof way by making the overlay way bigger than the screen*/
  top: -100vh;
  left: -100vw;
  width: calc(3 * 100vw);
  height: calc(3 * 100vh);
  background: black;
  opacity: 0.5;
  z-index: 2;
  ${cssForMediaSize({
    max: MediaSize.MEDIUM_SMALL,
    css: css`
      left: 0;
      width: 100%;
    `,
  })}
`;

const Searchbar = styled.div`
  display: flex;
  align-items: center;
  > * {
    flex-grow: 1;
    &:not(:last-child) {
      margin-right: ${spacing.xs};
    }
  }
`;

const DROPDOWN_LINK_SELECTED_CSS = css`
  background: var(${colorCssVars.background.faded});
`;

const DROPDOWN_LINK_CSS = [
  lightBgThemeCss,
  horizontalStackCss.xs,
  textSizeCss[TextSize.xs],
  css`
    border-radius: 500px;
    padding: ${spacing.xxs} ${spacing.xs} ${spacing.xxs} ${spacing.xxs};
    margin: ${spacing.xxs} ${spacing.xs} ${spacing.xxs} ${spacing.xxs};
    &:focus {
      outline: none;
    }
    &[data-focus-visible-added]:focus {
      ${DROPDOWN_LINK_SELECTED_CSS};
    }
    &:hover {
      background: var(${colorCssVars.background.faded});
    }
  `,
];

// Record when you last queried constructor and updated the result outside
// of the search compoment to ensure that an older query result never over-writes
// a newer query result, which can happen if request return out of order.
let lastContructorTime = 0;

const SearchTextInputWithRef = forwardRef<HTMLInputElement, SearchbarProps>(
  function SearchbarComponent(
    {
      rel = "search",
      className,
      escapeOverlayCss,
      focusCss,
      disableScrollLock,
      initiallyOpen = false,
      alwaysOpen = false,
      autoFocused,
      showTrendingNonprofits: initShowTrendingNonprofits,
      showTrendingCauses = true,
      featuredNonprofits,
      placeholder = null,
      autocompleteSection = [...Object.values(AutocompleteSection)],
      donateMethod,
      ...rest
    },
    parentInputRef
  ) {
    const { isLight } = useEdoTheme();
    const searchParams = useSearchOptionsFromUrl();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const searchState = useContext(SearchContext);
    const filterVisibilityState = useState(!searchParams.query);
    const setShowFilter = filterVisibilityState[1];
    const [searchFocused, setSearchFocused] = useState(
      autoFocused === undefined ? alwaysOpen || initiallyOpen : autoFocused
    );
    const blurTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const urlSearchOptions = useSearchOptionsFromUrl();
    const [inputValue, setInputValue] = useState<string>("");
    const allCauses = useAllCauses();
    const searchRoute = useSearchRoute();
    const user = useLoggedInUserOrUndefined();
    const authState = useContext(AuthContext);
    const loggedInUserId = user?.id;
    const searchPlaceholder = useStatSigLayer(
      "search_placeholder",
      !!placeholder
    ).get<string>("placeholder", "Explore causes to donate");
    if (!placeholder) {
      placeholder = searchPlaceholder || null;
    }

    useEffect(() => {
      if (!LocalSearchRouteNames.includes(searchRoute.name)) {
        setInputValue(urlSearchOptions?.query || "");
      }
    }, [searchRoute.name, urlSearchOptions?.query]);

    // eslint-disable-next-line no-restricted-syntax
    const isSmallScreen = useMatchesScreenSize({ max: MediaSize.MEDIUM_SMALL });

    useEffect(() => {
      if (!formRef.current || disableScrollLock || isSmallScreen) {
        return;
      }
      if (searchFocused) {
        disableBodyScroll(formRef.current);
      } else {
        enableBodyScroll(formRef.current);
      }
      return () => {
        clearAllBodyScrollLocks();
      };
    }, [disableScrollLock, searchFocused, isSmallScreen]);

    const [autocompleteResults, setAutocompleteResults] =
      useState<AutocompleteResultsMap>(new Map());
    const [trendingNonprofits, setTrendingNonprofits] = useState<
      NonprofitResponse[] | null
    >(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const blurSearch = useCallback(() => {
      !alwaysOpen && setSearchFocused(false);
      setSelectedIndex(null);
    }, [alwaysOpen]);

    const onSelectSuggestedTag = useCallback(() => {
      setShowFilter(false);
      blurSearch();
    }, [blurSearch, setShowFilter]);

    const router = useEdoRouter();
    const trendingTagsData = useTrendingTags();

    const { allSuggestions, suggestionGroups } = useMemo(() => {
      const onSuggestionClick = () => {
        setEntry(ClientTrackingRoute.SEARCH_DROPDOWN);
        blurSearch();
      };
      const suggestionGroup = (
        section: string,
        items: AutocompleteResult[]
      ) => {
        return {
          title: section,
          items: items.map((item) => ({
            selectedCss: DROPDOWN_LINK_SELECTED_CSS,
            node: (
              <AutocompleteUiItem
                item={item}
                onSuggestionClick={() => {
                  notifyConstructorClick({
                    section,
                    itemId: item.id,
                    userId: loggedInUserId,
                    input: inputValue,
                    actionType: "click",
                    groupId: undefined,
                    authState: authState,
                  });
                  onSuggestionClick();
                }}
                donateMethod={donateMethod}
                searchTerm={inputValue}
              />
            ),
            onEnterAction: () => {
              notifyConstructorClick({
                section,
                itemId: item.id,
                userId: loggedInUserId,
                input: inputValue,
                actionType: "click",
                groupId: undefined,
                authState: authState,
              });
              setEntry(ClientTrackingRoute.SEARCH_DROPDOWN, section);
              router.push(item.url);
              trackEvent(CustomEvent.SEARCH_SUGGESTION_CLICKED, {
                suggestionType: section,
              });
            },
          })),
        };
      };

      const isAutocompleteSection = (section: string) => {
        if (
          (Array.isArray(autocompleteSection) &&
            autocompleteSection.includes(section as AutocompleteSection)) ||
          autocompleteSection === section
        ) {
          return true;
        }

        return false;
      };

      const autocompleteSuggestionGroups: SuggestionGroup[] = [];

      const fundsData =
        isAutocompleteSection(AutocompleteSection.FUNDS) &&
        autocompleteResults.get(AutocompleteSection.FUNDS);
      const funds: SuggestionGroup | undefined = fundsData
        ? suggestionGroup(AutocompleteSection.FUNDS, fundsData)
        : undefined;
      const groupsData =
        isAutocompleteSection(AutocompleteSection.GROUPS) &&
        autocompleteResults.get(AutocompleteSection.GROUPS);
      const groups: SuggestionGroup | undefined = groupsData
        ? suggestionGroup(AutocompleteSection.GROUPS, groupsData)
        : undefined;
      const nonprofitsData =
        isAutocompleteSection(AutocompleteSection.NONPROFITS) &&
        autocompleteResults.get(AutocompleteSection.NONPROFITS);
      const nonprofits: SuggestionGroup | undefined = nonprofitsData
        ? suggestionGroup(AutocompleteSection.NONPROFITS, nonprofitsData)
        : undefined;
      const peopleData =
        isAutocompleteSection(AutocompleteSection.USERS) &&
        autocompleteResults.get(AutocompleteSection.USERS);
      const people: SuggestionGroup | undefined = peopleData
        ? suggestionGroup("people", peopleData)
        : undefined;
      const fundraisersData =
        isAutocompleteSection(AutocompleteSection.FUNDRAISERS) &&
        autocompleteResults.get(AutocompleteSection.FUNDRAISERS);
      const fundraisers: SuggestionGroup | undefined = fundraisersData
        ? suggestionGroup("fundraisers", fundraisersData)
        : undefined;

      const trendingTags =
        trendingTagsData === TrendingTagsFetchStatus.FETCHING_TRENDING_TAGS ||
        trendingTagsData === TrendingTagsFetchStatus.TRENDING_TAGS_NOT_FOUND ||
        trendingTagsData === undefined
          ? []
          : trendingTagsData.trendingTags;

      let filteredTagsSugestions =
        inputValue === ""
          ? trendingTags
          : new Fuse(trendingTags, {
              keys: ["title"],
              threshold: 0.3,
            })
              .search(inputValue)
              .map(({ item }) => item);

      filteredTagsSugestions = filteredTagsSugestions.slice(
        0,
        MAX_SEARCH_TRENDING
      );

      const filteredCauseSugestions =
        inputValue === ""
          ? VALID_CAUSE_CATEGORIES
          : new Fuse(
              VALID_CAUSE_CATEGORIES.map((cause) => ({
                cause,
                title: CauseMetadata[cause].title,
              })),
              {
                keys: ["title"],
                threshold: 0.3,
              }
            )
              .search(inputValue)
              .map(({ item }) => item.cause);

      const filteredAllCausesSugestions = new Fuse(allCauses, {
        keys: ["title"],
        threshold: 0.3,
      })
        .search(inputValue)
        .map(({ item }) => item);

      const filteredAllCausesSugestionsShow =
        filteredAllCausesSugestions.filter((cause) => cause.showInFilters);

      const allCausesSuggestionGroup: SuggestionGroup = {
        title: "Causes",
        rows:
          filteredAllCausesSugestionsShow.length > MAX_SHOW_CAUSES
            ? MAX_CAUSES_ROWS
            : undefined,
        items: filteredAllCausesSugestionsShow.map((cause) => ({
          selectedCss: DROPDOWN_LINK_SELECTED_CSS,
          node: (
            <Link
              title={`Navigate to trending cause ${cause.title}`}
              data-tname={`cause--${cause.title}`}
              appearance={LinkAppearance.UNSTYLED}
              css={DROPDOWN_LINK_CSS}
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.NONPROFIT_OR_CAUSE,
                tokens: { nonprofitSlug: cause.tagName },
              })}
              onClick={onSelectSuggestedTag}
              tabIndex={0} // without this, blur handler fails - relatedTarget === null
            >
              <TagIllustrationLabel size={AvatarSize.XX_SMALL} tag={cause} />
            </Link>
          ),
          onEnterAction: () => {
            router.push(
              getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.SEARCH_RESULTS,
                query: {
                  [SearchQueryParam.CAUSES]: cause.tagName,
                },
              })
            );
            trackEvent(CustomEvent.SEARCH_SUGGESTION_CLICKED, {
              suggestionType: "Causes",
            });
          },
        })),
      };

      const tagsSuggestionGroup: SuggestionGroup = {
        title: "Trending causes",
        items: filteredTagsSugestions
          .filter((tag) => tag.showInFilters)
          .map((tag) => ({
            selectedCss: DROPDOWN_LINK_SELECTED_CSS,
            node: (
              <Link
                title={`Navigate to trending cause ${tag.tagName}`}
                data-tname={`trendingCauses--${tag.tagName}`}
                appearance={LinkAppearance.UNSTYLED}
                css={DROPDOWN_LINK_CSS}
                to={getRoutePath({
                  format: URLFormat.RELATIVE,
                  name: ClientRouteName.NONPROFIT_OR_CAUSE,
                  tokens: { nonprofitSlug: tag.tagName },
                })}
                onClick={onSelectSuggestedTag}
                tabIndex={0} // without this, blur handler fails - relatedTarget === null
              >
                <TagIllustrationLabel
                  size={AvatarSize.XX_SMALL}
                  tag={tag}
                  imageAlt=" "
                />
              </Link>
            ),
            onEnterAction: () => {
              router.push(
                getRoutePath({
                  format: URLFormat.RELATIVE,
                  name: ClientRouteName.SEARCH_RESULTS,
                  query: {
                    [SearchQueryParam.CAUSES]: tag.tagName,
                  },
                })
              );
              trackEvent(CustomEvent.SEARCH_SUGGESTION_CLICKED, {
                suggestionType: "Trending Causes",
              });
            },
          })),
      };

      const trendingNonprofitsGroup = trendingNonprofits
        ? {
            title: "Trending nonprofits",
            rows: 2,
            items: trendingNonprofits.map(
              ({ logoCloudinaryId, id, primarySlug, name }) => {
                const url = getRoutePath({
                  name: ClientRouteName.NONPROFIT_OR_CAUSE,
                  tokens: {
                    nonprofitSlug: primarySlug,
                  },
                  format: URLFormat.RELATIVE,
                });
                return {
                  selectedCss: DROPDOWN_LINK_SELECTED_CSS,
                  node: (
                    <AutocompleteUiItem
                      item={{
                        title: name,
                        cloudinary_id: logoCloudinaryId || undefined,
                        slug: primarySlug,
                        url,
                        id,
                        section: AutocompleteSection.NONPROFITS,
                      }}
                      onSuggestionClick={onSuggestionClick}
                      donateMethod={donateMethod}
                      searchTerm={inputValue}
                    />
                  ),
                  onEnterAction: () => {
                    setEntry(
                      ClientTrackingRoute.SEARCH_DROPDOWN,
                      ClickAction.NONPROFIT
                    );
                    router.push(url);
                  },
                };
              }
            ),
          }
        : [];

      const featuredNonprofitsGroup =
        featuredNonprofits && featuredNonprofits.length > 0
          ? {
              title: "Featured nonprofits",
              items: featuredNonprofits.map(
                ({ logoCloudinaryId, id, primarySlug, name }) => {
                  const url = getDonateRoutePath({
                    nonprofitSlug: primarySlug,
                    format: URLFormat.RELATIVE,
                  });
                  return {
                    selectedCss: DROPDOWN_LINK_SELECTED_CSS,
                    node: (
                      <AutocompleteUiItem
                        item={{
                          title: name,
                          cloudinary_id: logoCloudinaryId || undefined,
                          slug: primarySlug,
                          url,
                          id,
                          section: AutocompleteSection.NONPROFITS,
                        }}
                        onSuggestionClick={onSuggestionClick}
                        donateMethod={donateMethod}
                        searchTerm={inputValue}
                      />
                    ),
                    onEnterAction: () => {
                      setEntry(
                        ClientTrackingRoute.SEARCH_DROPDOWN,
                        ClickAction.NONPROFIT
                      );
                      router.push(url);
                    },
                  };
                }
              ),
            }
          : [];

      const causeSuggestionGroup: SuggestionGroup = {
        title: "Top causes",
        items: filteredCauseSugestions.map((cause) => ({
          selectedCss: DROPDOWN_LINK_SELECTED_CSS,
          node: (
            <Link
              data-tname={`topCauses--${cause}`}
              appearance={LinkAppearance.UNSTYLED}
              css={DROPDOWN_LINK_CSS}
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.NONPROFIT_OR_CAUSE,
                tokens: { nonprofitSlug: TagNameByCauseCategory[cause] },
              })}
              onClick={onSelectSuggestedTag}
              tabIndex={0} // without this, blur handler fails - relatedTarget === null
            >
              <LabeledCauseIllustration
                size={AvatarSize.XX_SMALL}
                cause={cause}
              />
            </Link>
          ),
          onEnterAction: () => {
            router.push(
              getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.SEARCH_RESULTS,
                query: {
                  [SearchQueryParam.CAUSES]: TagNameByCauseCategory[cause],
                },
              })
            );
            trackEvent(CustomEvent.SEARCH_SUGGESTION_CLICKED, {
              suggestionType: "Top Causes",
            });
          },
        })),
      };

      const suggestionGroups: SuggestionGroup[] = [
        ...autocompleteSuggestionGroups,
      ].concat(
        funds ? funds : [],
        groups ? groups : [],
        nonprofits ? nonprofits : [],
        !inputValue ? featuredNonprofitsGroup : [],
        !inputValue ? trendingNonprofitsGroup : [],
        inputValue && filteredAllCausesSugestions.length
          ? allCausesSuggestionGroup
          : [],
        !inputValue && showTrendingCauses && filteredTagsSugestions.length
          ? tagsSuggestionGroup
          : [],
        !inputValue && filteredCauseSugestions.length
          ? causeSuggestionGroup
          : [],
        fundraisers ? fundraisers : [],
        people ? people : []
      );

      const allSuggestions: {
        item: SuggestionItem;
        id: string;
      }[] = suggestionGroups
        .map(({ items }, groupIndex) =>
          items.map((item, index) => ({ item, id: `${groupIndex}-${index}` }))
        )
        .reduce((acc, items) => acc.concat(items), []); // flatten
      return { suggestionGroups, allSuggestions };
    }, [
      trendingTagsData,
      inputValue,
      trendingNonprofits,
      featuredNonprofits,
      showTrendingCauses,
      blurSearch,
      autocompleteResults,
      autocompleteSection,
      donateMethod,
      router,
      onSelectSuggestedTag,
      loggedInUserId,
      authState,
      allCauses,
    ]);

    const selectedSuggestion =
      typeof selectedIndex !== "number" && !selectedIndex
        ? null
        : allSuggestions[selectedIndex];

    const onEnter = useCallback(() => {
      if (selectedSuggestion) {
        selectedSuggestion.item.onEnterAction();
      }
    }, [selectedSuggestion]);

    const onArrowDown = useCallback(() => {
      setSelectedIndex(
        selectedIndex === null
          ? 0
          : wrapIndex({
              index: selectedIndex + 1,
              numItems: allSuggestions.length,
            })
      );
    }, [allSuggestions.length, selectedIndex]);

    const onArrowUp = useCallback(() => {
      setSelectedIndex(
        selectedIndex === null
          ? allSuggestions.length - 1
          : wrapIndex({
              index: selectedIndex - 1,
              numItems: allSuggestions.length,
            })
      );
    }, [allSuggestions.length, selectedIndex]);

    const handleKeydown = useCallback(
      (event: KeyboardEvent) => {
        switch (event.keyCode) {
          case 27:
            blurSearch();
            break;
          case 40:
            onArrowDown();
            break;
          case 38:
            onArrowUp();
            break;
          case 13:
            onEnter();
            break;
        }
      },
      [onArrowDown, onArrowUp, onEnter, blurSearch]
    );

    useEffect(() => {
      const document = getWindow()?.document;
      if (!document || !searchFocused) {
        return;
      }

      document.addEventListener("keydown", handleKeydown, false);
      return () => {
        document.removeEventListener("keydown", handleKeydown, false);
      };
    }, [handleKeydown, searchFocused]);

    useEffect(() => {
      async function autocomplete() {
        const time = Date.now();
        const searchResults = await queryConstructor(inputValue);
        if (time > lastContructorTime) {
          lastContructorTime = time;
          setAutocompleteResults(searchResults);
        }
      }
      autocomplete();
    }, [inputValue]);

    useEffect(() => {
      // Show the top 5 most shown nonprofits in the public home feed as trending
      // If the nonprofit does not have a logo and cover picture penalize it a lot
      async function getTrendingNonprofits() {
        const fetchPublicHomeFeedPromise = fetchPublicHomeFeed({
          skip: 0,
          take: 30,
        } as ListParams);
        const pinnedPromise = PINNED_TRENDING_SLUGS.map((s) =>
          fetchNonprofit({ slug: s })
        );
        const { nonprofits, items } = await fetchPublicHomeFeedPromise;
        const pinned = await Promise.all(pinnedPromise);
        const nonprofitsMap = new Map(
          nonprofits.map((nonprofit) => [nonprofit.id, { nonprofit, score: 0 }])
        );
        const now = new Date().getTime();
        for (const item of items) {
          if (item.type === FeedItemType.NONPROFIT_RECOMMENDATION) {
            const data = nonprofitsMap.get(item.nonprofitId);
            if (data) {
              const hasPictures =
                !!data?.nonprofit.logoCloudinaryId &&
                !!data?.nonprofit.coverImageCloudinaryId;
              data.score += hasPictures ? 0.5 : 0.001;
              data.score *=
                (data.nonprofit.supporterInfo?.numSupporters || 0) > 2 ? 1 : 0;
            }
          } else if (item.type === FeedItemType.USER_DONATION) {
            const data = nonprofitsMap.get(item.donationCharge.toNonprofitId);
            if (data) {
              const hasPictures =
                !!data?.nonprofit.logoCloudinaryId &&
                !!data?.nonprofit.coverImageCloudinaryId;
              const diffMs =
                now - item.donationCharge.donation.createdAt.getTime();
              const score = Math.pow(0.98, diffMs / 86400000); // ms to days with exponential decay
              data.score += hasPictures ? score : 0.001 * score;
              data.score *=
                (data.nonprofit.supporterInfo?.numSupporters || 0) > 2 ? 1 : 0;
            }
          }
        }
        const nonprofitRecomendations = [
          ...pinned,
          ...[...nonprofitsMap.values()]
            .sort(
              (
                a: { nonprofit: NonprofitResponse; score: number },
                b: { nonprofit: NonprofitResponse; score: number }
              ) => (a.score < b.score ? 1 : a.score > b.score ? -1 : 0)
            )
            .map((x) => x.nonprofit),
        ]
          .filter((n): n is NonprofitResponse => !!n)
          .slice(0, 5);
        setTrendingNonprofits(nonprofitRecomendations);
      }

      getTrendingNonprofits();
    }, []);

    // in some situations, when the search input gets focused on it may not
    // actually end up focusing the text input this
    // ensures it always does receive focus
    useEffect(() => {
      if (searchFocused && inputRef.current) {
        inputRef.current.focus();
      }
    }, [searchFocused]);

    const onSubmit = (e: FormEvent | KeyboardEvent) => {
      e.preventDefault();
      if (!submitSearchAction || selectedIndex !== null) {
        return;
      }

      const cause = allCauses.find(
        ({ title }) =>
          title.toLocaleLowerCase() === inputValue.trim().toLocaleLowerCase()
      );

      if (cause) {
        trackEvent(CustomEvent.SEARCH_EXACT_CAUSE, {
          cause: cause.tagName,
        });
        submitSearchAction({
          ...urlSearchOptions,
          causes: cause.tagName,
          query: "",
        });
        return;
      }

      submitSearchAction({
        ...urlSearchOptions,
        query: inputValue,
      });

      blurSearch();
    };

    return (
      <SearchForm
        className={className}
        role="search"
        action="/search"
        onSubmit={onSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit(e);
          }
        }}
        ref={formRef}
        // handle blurring using a timeout as documented in React docs
        // https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
        onBlur={(event) => {
          const { currentTarget, relatedTarget } = event;
          blurTimeoutRef.current = setTimeout(() => {
            if (
              !relatedTarget ||
              !(relatedTarget instanceof Element) ||
              !currentTarget.contains(relatedTarget)
            ) {
              blurSearch();
            }
          }, 0);
        }}
        onFocus={() =>
          blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current)
        }
      >
        <Searchbar>
          <StyledTextInput
            inputBoxCss={[
              !isLight && lightBgThemeCss,
              searchFocused && focusedSearchCss,
              focusCss && focusCss,
            ]}
            {...rest}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
            type={TextInputType.SEARCH}
            value={inputValue}
            ref={composeRefs(parentInputRef, inputRef)}
            placeholder={placeholder || undefined}
            inputPrefix={
              searchState.value === SearchStateValue.SEARCHING ? (
                <LoadingIcon
                  css={loadingIconStyle}
                  size={IconSize.MEDIUM}
                  display={IconDisplay.SECONDARY}
                />
              ) : (
                <Icon
                  iconImport={() => import("@components/Icon/icons/SearchIcon")}
                  css={iconStyle}
                  size={IconSize.MEDIUM}
                  display={IconDisplay.SECONDARY}
                />
              )
            }
            setInputPrefixColorAndHeight={false}
            inputSuffix={
              !!inputValue && (
                <Button
                  title="Cancel search"
                  data-tname="SearchTextInput--clear"
                  role={ButtonRole.UNSTYLED}
                  onClick={{
                    kind: ButtonTargetKind.FUNCTION,
                    action: (e) => {
                      e.stopPropagation();
                      if (inputValue.length > 0) {
                        setInputValue("");
                        // submit empty search string only on search results page
                        if (submitSearchAction && searchRoute.isCurrent) {
                          submitSearchAction({
                            ...urlSearchOptions,
                            query: "",
                          });
                        }
                      }
                      blurSearch();
                    },
                  }}
                >
                  <Icon
                    iconImport={() => import("@components/Icon/icons/XIcon")}
                    size={IconSize.MEDIUM}
                    display={IconDisplay.ACCENT}
                  />
                </Button>
              )
            }
            onFocus={() => {
              setSearchFocused(true);
            }}
            collapseDescriptionSpace
            aria-haspopup="true"
          />
        </Searchbar>
        {(searchFocused || alwaysOpen) && (
          <React.Fragment>
            <EscapeOverlay
              css={escapeOverlayCss}
              onClick={() => {
                blurSearch();
              }}
            />
            <BrowseDropdownContainer data-tname="search-dropdown">
              <BrowseDropdown
                groups={suggestionGroups}
                selectedId={
                  typeof selectedSuggestion !== "number" && !selectedSuggestion
                    ? null
                    : selectedSuggestion.id
                }
              />
              {inputValue ? (
                <Button
                  data-tname={"searchDropdownViewAllButton"}
                  role={ButtonRole.TEXT_ONLY}
                  onClick={{
                    kind: ButtonTargetKind.SUBMIT,
                  }}
                >
                  <span
                    // without this, blur handler fails on Firefox - relatedTarget === null
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                  >
                    View all results
                  </span>
                </Button>
              ) : (
                <Button
                  data-tname={"searchDropdownExploreAllCauses"}
                  role={ButtonRole.TEXT_ONLY}
                  onClick={{
                    kind: ButtonTargetKind.FUNCTION,
                    action: () => {
                      setSearchFocused(false);
                      router.push(
                        getRoutePath({
                          name: ClientRouteName.CAUSES,
                          format: URLFormat.RELATIVE,
                          query: {
                            [FILTER_MODAL_URL_PARAM]: "true",
                          },
                        })
                      );
                    },
                  }}
                >
                  <span
                    // without this, blur handler fails on Firefox - relatedTarget === null
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                  >
                    Explore all causes
                  </span>
                </Button>
              )}
            </BrowseDropdownContainer>
          </React.Fragment>
        )}
      </SearchForm>
    );
  }
);
/**
 * Text input that triggers a search when input into
 *
 * There may only be one of these rendered on screen at a time
 */
export const SearchTextInput = React.memo(SearchTextInputWithRef);

const BrowseDropdown: React.FCC<{
  groups: SuggestionGroup[];
  selectedId: string | null;
}> = ({ groups, selectedId }) => {
  return (
    <React.Fragment>
      {groups.map((group, groupIndex) => {
        const titleKebabCase = group.title.toLowerCase().replaceAll(" ", "-");
        return [
          <h3
            key={`title-${groupIndex}`}
            data-tname={`search-dropdown-title-${titleKebabCase}`}
          >
            {group.title}
          </h3>,
          <ul
            key={`items-${groupIndex}`}
            data-tname={`search-dropdown-list-${titleKebabCase}`}
            css={group.rows && suggestionGroupListCss(group.rows)}
          >
            {group.items.map((item, index) => {
              const id = `${groupIndex}-${index}`;
              return (
                <li
                  key={id}
                  css={[
                    selectedId !== null &&
                      selectedId === id &&
                      css`
                        & > * {
                          ${item.selectedCss};
                        }
                      `,
                    css`
                      span > span {
                        max-width: 250px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    `,
                  ]}
                >
                  {item.node}
                </li>
              );
            })}
          </ul>,
        ];
      })}
    </React.Fragment>
  );
};

function fundraiserLink(item: AutocompleteResult) {
  const url = `/${item.slug}`;
  return (
    <Link appearance={LinkAppearance.UNSTYLED} data-tname="fundraiser" to={url}>
      <RawAvatar
        size={AvatarSize.XX_SMALL}
        cloudinaryId={item.cloudinary_id}
        drawBorder
        placeholder={<PlaceholderIllustration css={PLACEHOLDER_CSS} />}
        alt={"Fundraiser logo"}
      />
    </Link>
  );
}

const AutocompleteUiItem: React.FCC<{
  item: AutocompleteResult;
  onSuggestionClick: () => void;
  donateMethod?: DonationFlowPaymentOption | DonationFlowPaymentOption[];
  searchTerm?: string;
}> = ({ item, onSuggestionClick, donateMethod, searchTerm }) => {
  const isNonprofitsSection = [
    AutocompleteSection.NONPROFITS,
    AutocompleteSection.FUNDS,
  ].includes(item.section);

  const shouldAddSearchMeta = isNonprofitsSection && searchTerm;

  const methodString = donateMethod
    ? typeof donateMethod === "string"
      ? donateMethod
      : donateMethod.join(",")
    : undefined;

  const content = (
    <span css={[horizontalStackCss.xs, { alignItems: "center" }]}>
      {item.section === AutocompleteSection.USERS ? (
        <UserAvatar
          size={AvatarSize.XX_SMALL}
          disableLink
          user={{
            profileImageCloudinaryId:
              item.cloudinary_id || item.image_url || "",
            username:
              (item.username as Username) || (item.id as Username) || undefined,
          }}
        />
      ) : item.section === AutocompleteSection.FUNDRAISERS ? (
        fundraiserLink(item)
      ) : (
        <NonprofitAvatar
          size={AvatarSize.XX_SMALL}
          disableLink
          nonprofit={{
            logoCloudinaryId: item.cloudinary_id || item.image_url || "",
            primarySlug: item.slug || item.id || "",
            name: item.title || item.slug || "",
          }}
        />
      )}
      <span>{item.title}</span>
    </span>
  );

  const url = useMemo(() => {
    const baseUrl = new URL(item.url || `/${item.slug}`, WEBSITE_ORIGIN);

    if (shouldAddSearchMeta) {
      baseUrl.searchParams.set(
        DonateModalUrlParams.SEARCH_META,
        JSON.stringify({ query: searchTerm })
      );
    }

    if (methodString && isNonprofitsSection) {
      baseUrl.searchParams.set(DonateModalUrlParams.METHOD, methodString);
      baseUrl.hash = DONATE_HASH;
    }

    // remove origin to make it relative
    return baseUrl.toString().replace(baseUrl.origin, "");
  }, [
    isNonprofitsSection,
    item.url,
    item.slug,
    methodString,
    searchTerm,
    shouldAddSearchMeta,
  ]);

  return (
    <Link
      data-tname="searchAutocomplete"
      appearance={LinkAppearance.UNSTYLED}
      css={DROPDOWN_LINK_CSS}
      onClick={onSuggestionClick}
      tabIndex={0} // without this, blur handler fails - relatedTarget === null
      to={url}
    >
      {content}
    </Link>
  );
};
