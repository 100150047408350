import {
  Button,
  ButtonTargetKind,
  ButtonSize,
  ButtonRole,
  OnClick,
} from "@components/Button";
import { Card } from "@components/Card";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";
import styled from "@emotion/styled";
import React from "react";

import { spacing } from "@every.org/common/src/display/spacing";

import {
  ToastType,
  removeToast,
  ToastRole,
  EXIT_ANIMATION_DURATION,
  useToasts,
} from "src/components/ToastManager/Toasts";
import { colorCssVars, darkBgThemeCss } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

const ENTER_ANIMATION_DURATION = 500;

const exitAnimation = css`
  animation-name: exit;
  animation-timing-function: ease-out;
  animation-duration: ${EXIT_ANIMATION_DURATION}ms;
  @keyframes exit {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  transform: translateY(-100%);
  opacity: 0;
`;

const enterAnimation = css`
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-duration: ${ENTER_ANIMATION_DURATION}ms;
  @keyframes bounce {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    10% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-33%);
    }
    50% {
      transform: translateY(0);
    }
    64% {
      transform: translateY(-3%);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const ToastsContainer = styled.div`
  ${verticalStackCss.xs}
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: verticalStackCss.m,
  })}
  
    pointer-events: none;
  position: absolute;
  left: ${spacing.s};
  right: ${spacing.s};
  bottom: ${spacing.m};
  align-items: center;
`;

const StyledCard = styled(Card)`
  pointer-events: all;
  padding: ${spacing.m};
  ${horizontalStackCss.s};
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;

  b {
    font-weight: ${FontWeight.BOLD};
  }
`;

const toastRoleToCss: { [key in ToastRole]: CSSInterpolation | null } = {
  [ToastRole.ERROR]: css`
    ${darkBgThemeCss};
    background: var(${colorCssVars.input.border.error});
  `,
  [ToastRole.ACCENT]: css`
    ${darkBgThemeCss};
    background: var(${colorCssVars.accent.largeHighlight});
  `,
  [ToastRole.MESSAGE]: null,
};

const toastRoleToIconDisplay: { [key in ToastRole]: IconDisplay } = {
  [ToastRole.ERROR]: IconDisplay.CURRENT_COLOR,
  [ToastRole.ACCENT]: IconDisplay.CURRENT_COLOR,
  [ToastRole.MESSAGE]: IconDisplay.ACCENT,
};

export const ToastManager: React.FCC<{ className?: string }> = ({
  className,
}) => {
  const toastsContext = useToasts();
  const toasts = Array.from(toastsContext.entries()).map(
    ([type, { message, ...rest }]) => {
      return (
        <Toast key={type} type={type} {...rest}>
          {message}
        </Toast>
      );
    }
  );

  return (
    <PageSection
      className={className}
      css={css`
        position: sticky;
        bottom: ${spacing.m};
      `}
    >
      <ToastsContainer>{toasts}</ToastsContainer>
    </PageSection>
  );
};

const toastActionCss = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

interface ToastProps {
  role: ToastRole;
  type: ToastType;
  onClick?: OnClick;
  markedToExit?: boolean;
  removeOnClick?: boolean;
}

const Toast: React.FCC<ToastProps> = ({
  role,
  type,
  markedToExit,
  onClick,
  children,
}) => {
  return (
    <StyledCard
      css={[
        toastRoleToCss[role],
        markedToExit ? exitAnimation : enterAnimation,
      ]}
      data-tname={`${type}_Toast`}
    >
      <span>{children}</span>
      <Button
        data-tname={`${type}_Toast-close`}
        css={css`
          margin-left: "auto";
          z-index: 1;
        `}
        size={ButtonSize.SMALL}
        role={ButtonRole.UNSTYLED}
        icon={
          <Icon
            iconImport={() => import("@components/Icon/icons/XIcon")}
            size={IconSize.MEDIUM}
            display={toastRoleToIconDisplay[role]}
          />
        }
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => removeToast(type),
        }}
      />
      {onClick && (
        <Button
          data-tname={`${type}_Toast-onClick`}
          onClick={onClick}
          role={ButtonRole.UNSTYLED}
          css={toastActionCss}
        />
      )}
    </StyledCard>
  );
};
