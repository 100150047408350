import { IconSize } from "@components/Icon";
import { VerifiedStatusIcon } from "@components/VerifiedStatusIcon";
import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";
import React from "react";

import { UserResponse, Uuid } from "@every.org/common/src/codecs/entities";
import { VerifiedStatus } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";

import { Link } from "src/components/Link";
import { useUser } from "src/context/UsersContext/hooks";
import { userOrUndefined } from "src/context/UsersContext/selectors";
import { LinkAppearance } from "src/styles/link";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { horizontalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";
import { ClickAction } from "src/utility/analytics";
import { logger } from "src/utility/logger";

interface UserIdProps {
  userId: Uuid;
  onClick?: () => void;
  appearance?: LinkAppearance;
  tname?: string;

  // Show only first name instead of full name
  firstNameOnly?: boolean;
}

// Component that fetches a user based on their ID and displays their name + links to their profile
export const UserIdLink: React.FCC<UserIdProps> = ({
  userId,
  onClick,
  appearance,
  tname,
  firstNameOnly,
}) => {
  const user = userOrUndefined(useUser({ id: userId }));
  if (!user) {
    return null;
  }

  return (
    <UserLink
      user={user}
      appearance={appearance}
      onClick={onClick}
      tname={tname}
      firstNameOnly={firstNameOnly}
    />
  );
};

type UserFields = Pick<
  UserResponse,
  "username" | "id" | "firstName" | "lastName" | "verifiedStatus"
>;

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

interface UserLinkProps {
  user: Optional<UserFields, "verifiedStatus">;
  onClick?: () => void;
  appearance?: LinkAppearance;
  contentCss?: CSSInterpolation | CSSInterpolation[];
  tname?: string;

  // Show only first name instead of full name
  firstNameOnly?: boolean;
  isTextLink?: boolean;
  newTab?: boolean;
}

// Component to display the user's name and link to it if possible
export const UserLink: React.FCC<UserLinkProps> = ({
  user,
  onClick,
  tname = "user",
  appearance,
  contentCss,
  firstNameOnly,
  isTextLink = false,
  newTab = false,
}) => {
  const name = firstNameOnly
    ? user.firstName
    : getUserFullNameOrPlaceholder(user);
  if (!name) {
    logger.warn({
      message:
        "Can't create UserLink for user " +
        user.id +
        " because of missing name",
    });
    return <React.Fragment />;
  }

  return (
    <UserNameLink
      username={user.username}
      name={name}
      tname={tname}
      appearance={appearance}
      contentCss={contentCss}
      onClick={onClick}
      verifiedStatus={user.verifiedStatus}
      isTextLink={isTextLink}
      newTab={newTab}
    />
  );
};

interface UserNameLinkProps {
  name: string;
  disabled?: boolean;
  username?: string | null;
  onClick?: () => void;
  appearance?: LinkAppearance;
  contentCss?: CSSInterpolation | CSSInterpolation[];
  tname: string;
  verifiedStatus?: VerifiedStatus;
  isTextLink?: boolean;
  newTab?: boolean;
}

// Component to display a name and link to the corresponding user if provided a username
export const UserNameLink: React.FCC<UserNameLinkProps> = ({
  name,
  username,
  tname,
  onClick,
  appearance = LinkAppearance.HYPERLINK,
  contentCss,
  disabled = false,
  verifiedStatus,
  isTextLink = false,
  newTab = false,
}) => {
  if (!disabled && isTextLink && username) {
    return (
      <Link
        data-tname={tname}
        to={getRoutePath({
          format: URLFormat.RELATIVE,
          name: ClientRouteName.USER,
          tokens: { username },
        })}
        css={contentCss}
        appearance={appearance}
        target={newTab ? "_blank" : undefined}
      >
        {name}
      </Link>
    );
  }

  if (!disabled && username) {
    return (
      <div
        css={[
          horizontalStackCss.xxs,
          css`
            align-items: center;
          `,
        ]}
      >
        <Link
          data-tname={tname}
          data-action={ClickAction.USER}
          onClick={onClick}
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.USER,
            tokens: { username },
          })}
          appearance={appearance}
          css={[
            contentCss,
            truncatedTextCss({ numLines: 1 }),
            css`
              word-break: break-all;
            `,
          ]}
          target={newTab ? "_blank" : undefined}
        >
          {name}
        </Link>
        <VerifiedStatusIcon
          verifiedStatus={verifiedStatus}
          size={IconSize.X_SMALL}
        />
      </div>
    );
  }

  return (
    <span
      css={[
        css`
          font-weight: ${FontWeight.MEDIUM};
        `,
        truncatedTextCss({ numLines: 1 }),
      ]}
    >
      {name}
    </span>
  );
};
