import { css } from "@emotion/react";
import type { CSSInterpolation } from "@emotion/serialize";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";
import {
  objectFromEnumValues,
  readonly,
} from "@every.org/common/src/helpers/objectUtilities";

import { wrapStyleInFocusBlock } from "src/styles/common";
import { colorCssVars } from "src/theme/color";
import { FontWeight } from "src/theme/text";

export enum LinkAppearance {
  HYPERLINK = "H",
  HYPERLINK_UNCOLORED = "H_U",
  HYPERLINK_SECONDARY = "H_S",
  UNSTYLED = "U",
  /**
   * Workaround to make buttons render properly due to issue with CSS specificity
   */
  UNSTYLED_NO_COLOR = "U_N",
}

const linkColorCssVar = "--linkColor";
const linkColorHoverCssVar = "--linkHover";

export const linkFocusCss = css`
  outline: none;
  box-shadow: 0px 0px 4px ${SHARED_PALETTE.focus};
`;
const baseHyperlinkStyle = [
  css`
    font-weight: ${FontWeight.MEDIUM};
    transition: color 0.1s ease-in, text-decoration-color 0.1s ease-in;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: transparent;
    display: inline-flex;
    align-items: center;

    &:hover {
      text-decoration-color: currentColor;
    }

    color: var(${linkColorCssVar});
    &:hover {
      color: var(${linkColorHoverCssVar});
    }
  `,
  wrapStyleInFocusBlock(linkFocusCss),
];

const unstyledBaseLinkStyle = css`
  &,
  &:hover {
    text-decoration: none;
  }
`;
const unstyledLinkStyle = [
  unstyledBaseLinkStyle,
  css`
    &,
    &:hover {
      color: inherit;
    }
  `,
];

const linkColorByAppearance: {
  [key in Exclude<
    LinkAppearance,
    LinkAppearance.UNSTYLED | LinkAppearance.UNSTYLED_NO_COLOR
  >]: CSSInterpolation;
} = {
  [LinkAppearance.HYPERLINK]: {
    [linkColorCssVar]: `var(${colorCssVars.accent.large})`,
    [linkColorHoverCssVar]: `var(${colorCssVars.accent.largeHighlight})`,
  },
  [LinkAppearance.HYPERLINK_UNCOLORED]: {
    [linkColorCssVar]: `var(${colorCssVars.text.body})`,
    [linkColorHoverCssVar]: `var(${colorCssVars.text.secondary})`,
  },
  [LinkAppearance.HYPERLINK_SECONDARY]: {
    [linkColorCssVar]: `var(${colorCssVars.text.secondary})`,
    [linkColorHoverCssVar]: `var(${colorCssVars.text.body})`,
  },
};

export const linkStyleByAppearance = readonly(
  objectFromEnumValues({
    enum: LinkAppearance,
    mapFn: (appearance): CSSInterpolation => {
      switch (appearance) {
        case LinkAppearance.UNSTYLED:
          return unstyledLinkStyle;
        case LinkAppearance.UNSTYLED_NO_COLOR:
          return unstyledBaseLinkStyle;
        default:
          return [baseHyperlinkStyle, linkColorByAppearance[appearance]];
      }
    },
  })
);
